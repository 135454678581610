<template>
  <header
    class="header-block"
    :class="{ clean }"
  >
    <div class="g-container">
      <div class="inner">
        <div class="top-row">
          <div class="logo">
            <router-link
              to="/"
              class="logo-link"
              :class="{ disabled: $route.name === 'main' }"
            >
              <img
                class="logo-img"
                src="/images/logo.svg"
                alt="logo"
              >
            </router-link>
          </div>
          <form
            v-if="!clean"
            class="search-form"
            @submit.prevent="fetchProducts"
          >
            <div class="search">
              <input
                type="text"
                class="g-input search-input"
                v-model="searchRequest"
              >
              <img
                src="/images/icons/search.svg"
                alt="search icon"
                class="search-icon"
                @click="fetchProducts"
              >
            </div>
            <button
              type="button"
              class="g-button filter-button"
              :class="{ active: areFiltersOpened }"
              @click.stop.prevent="areFiltersOpened = !areFiltersOpened"
            >
              <img
                src="/images/icons/filter.svg"
                alt="filters"
                width="30"
                height="30"
              >
            </button>
          </form>
          <div class="menu"></div>
        </div>
        <SlideUpDown
          :active="areFiltersOpened"
          :duration="200"
        >
          <transition name="fade">
            <div
              v-if="!clean && stores.length"
              class="filters"
            >
              <label class="g-label filter">
                <div class="g-label-inner">
                  Sort by
                </div>
                <select
                  class="g-select"
                  v-model="sorting"
                  @change="fetchProducts"
                >
                  <option value="relevance">Relevance</option>
                  <option value="price_from_low">Lowest price</option>
                  <option value="price_from_high">Highest price</option>
                  <option value="sale.price_from_low">Lowest Sale price</option>
                  <option value="sale.price_from_high">Highest Sale price</option>
                </select>
              </label>
              <label class="g-label filter">
                <div class="g-label-inner">
                  Store filter
                </div>
                <select
                  class="g-select"
                  v-model="store"
                  @change="fetchProducts"
                >
                  <option value="all">All</option>
                  <option
                    v-for="store in stores"
                    :key="store"
                    :value="store"
                  >{{ store }}</option>
                </select>
              </label>
            </div>
          </transition>
        </SlideUpDown>
      </div>
    </div>
  </header>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    clean: Boolean,
  },

  components: {
    SlideUpDown,
  },

  data: () => ({
    searchRequest: '',
    sorting: 'relevance',
    store: 'all',
    areFiltersOpened: false,
  }),

  computed: {
    ...mapGetters(['stores', 'page', 'offset']),
  },

  beforeMount() {
    if (this.$route.query.search) {
      this.searchRequest = this.$route.query.search;
      document.title = `INVI - ${this.$route.query.search}`;
    }

    if (this.$route.query.sorting) {
      this.sorting = this.$route.query.sorting;
    }

    if (this.$route.query.store) {
      this.store = this.$route.query.store;
    }

    if (this.sorting !== 'relevance' || this.store !== 'all') {
      this.areFiltersOpened = true;
    }
  },

  methods: {
    ...mapActions(['fetchProductList', 'setPage']),
    fetchProducts() {
      const { id, ...otherRouteQuery } = this.$route.query;
      const query = {
        ...otherRouteQuery,
        search: this.searchRequest,
        sorting: this.sorting,
        store: this.store,
      };

      this.setPage(1);
      this.fetchProductList({ ...query, page: this.page }).then(() => {
        this.$router.push({
          query: this.getQueryWithNotEmpty({ ...query, page: this.page }),
          name: this.$route.name,
        });
      });
    },

    getQueryWithNotEmpty(query) {
      return Object.keys(query).reduce((result, key) => {
        if (query[key]) {
          result[key] = query[key];
        }

        return result;
      }, {});
    },
  },

  watch: {
    $route() {
      document.title = this.$route.query.search ? `INVI - ${this.$route.query.search}` : 'INVI';
    },
  },
};
</script>

<style lang="scss">
.header-block {
  background-color: $color-brand;
  font-family: 'Comfortaa', sans-serif;
  &.clean {
    .logo {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }

  .logo {
    position: relative;
  }

  .logo-link {
    &.disabled {
      pointer-events: none;
    }
  }

  .logo-img {
    width: 200px;
  }

  .search-form {
    position: relative;
    padding-left: 20px;
    display: flex;
    flex-grow: 1;
  }

  .search {
    position: relative;
    flex-grow: 1;
  }

  .search-input {
    font-size: 20px;
    padding: 15px;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .filter-button {
    opacity: .9;
    transition: opacity .2s;
    margin-left: 20px;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .filters {
    padding-bottom: 15px;
    padding-right: 60px;
    display: flex;
    justify-content: flex-end;
  }

  .filter {
    margin-left: 25px;
    margin-top: 0;
  }
}

@media (max-width: $mobile-width) {
  .header-block {
    .top-row {
      flex-wrap: wrap;
    }

    .logo {
      width: 100%;
      text-align: center;
    }

    .logo-img {
      width: 100px;
      margin-right: 0;
    }

    .search-form {
      padding-left: 0;
    }

    .filters {
      padding-right: 0;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
    }

    .filter {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}
</style>
